<template>
  <div class="page">
    <!-- <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="阅读授权" name="阅读授权">
        <RequestList ref="request" :tabBtnShow="true" @onloaData="onloaData" @onLoadRefreshList="onLoadRefreshList"></RequestList>
      </el-tab-pane>
      <el-tab-pane label="申请授权" name="申请授权">
        <RequestList ref="requestList" :tabBtnShow="false" @onLoadRefreshList="onLoadRefreshList"></RequestList>
      </el-tab-pane>
    </el-tabs> -->
    <div class="apply-auth-headtab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="阅读授权" name="阅读授权">
              <RequestList ref="request" :tabBtnShow="true" @onloaData="onloaData" @onLoadRefreshList="onLoadRefreshList"></RequestList>
            </el-tab-pane>
            <el-tab-pane label="申请授权" name="申请授权">
              <RequestList ref="requestList" :tabBtnShow="false" @onLoadRefreshList="onLoadRefreshList"></RequestList>
            </el-tab-pane>
        </el-tabs>
    </div>
  
    <div class="pagination">
      <el-pagination
        v-if='paging.total != 0'
        @current-change="pageChanged"
        layout="total,  prev, pager, next, jumper"
        :page-size="page.pages"
        :total="paging.total">
      </el-pagination>
    </div>
</div>
</template>
<script>
import RequestList from './RequestList.vue'
  export default {
    name:'requestList',
    data(){
      return {
        activeName:'',
        page:{
          total:0, // 页数
          pages:20 // 条数
        },
        
        request:{},
        requestList:{},
        viewerKey:'receiver'
      }
    },
    components: { RequestList },
    computed: {
      paging(){
       return this.$store.state.request.views[this.viewerKey].paging
      }
    },
    methods:{
      pageChanged(num){
        if(this.activeName=='阅读授权'){
          this.readLists('request',num)
        } else {
          this.applyLists('requestList',num)
        }
      },
      goDetail(){ 

      },
      // tab申请切换
      handleClick({label}){
        if(label==='阅读授权'){
          this.activeName = '阅读授权'
          this.readLists('request',1)
          // this.page.total = this.request.total
        } else {
          this.activeName = '申请授权'
          this.applyLists('requestList',1)
          // this.page.total = this.requestList.total
        }
      },
      onloaData(){
        this.readLists('request',1)
      },
      readLists(list,pageNum) {
        this.$store.commit('request/updateQuery', {
          vkey: this.viewerKey,
          query:{
            type:['0'],
            pageNum,
          }
        })
        this.loadList(true,list,true,false)
      },
      applyLists(list,pageNum) {
        this.$store.commit('request/updateQuery', {
          vkey: this.viewerKey,
          query:{
            type:['1'],
            pageNum,
          }
        })
        this.loadList(true,list,false,false)
      },
      loadList(flag,list,load=false,noRest=true) {
        this.$store.dispatch("request/loadReceiveByMe", {
          reset: flag ? true : false,
          noRest
        }).then(res=>{
          if(res){
            if(load){
              this.page.total = this.$store.state.request.views[this.viewerKey].paging.total
            }
            this.$refs[list].loading = false
            this[list] = this.$store.state.request.views[this.viewerKey].paging
            this.$refs[list].list = {
              list: this.$store.state.request.views[this.viewerKey].list,
              applyType:list
            }
          } else {
            this.$refs[list].loading = false
             return Promise.reject(false)
          }
        })
      },
      onLoadRefreshList(){
         if(this.activeName=='阅读授权'){
          this.readLists('request',1)
        } else {
          this.applyLists('requestList',1)
        }
      }
    },
    // async created(){
    //   await this.readLists('request',1)
    //   // await this.applyLists('requestList',1)
    //   let {requestActiveName} = this.$store.state.request.views[this.viewerKey]
    //   this.activeName = requestActiveName!=='' ? requestActiveName : '阅读授权'
    // },
    mounted() {
      this.activeName = '阅读授权';
      this.readLists('request',1)
    },
  }
</script>

<style lang="less" scoped>
/deep/ .main-bread-heads {
    height: 0px !important;
}
.page {
  box-sizing: border-box;
  .el-tabs--border-card {
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    border: 0px;
    /deep/ .el-tabs__nav {
      .el-tabs__item {
        background: rgba(255, 153, 0, 1);
        color: white;
       /*  border-radius: 15px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-top: 5px; */
      }
      .is-active {
        background: rgba(153, 0, 0, 1);
      }
      /* .el-tabs__item:nth-child(1){
        margin-left: 10px;
      } */
    } 
}
.apply-auth-headtab {
    width: 100%;
    display: flex;
    align-items: center;
    /deep/ .el-tabs.el-tabs--top {
      width: 100%;
        .el-tabs__item {
            font-size: 16px;
        }
        .is-active {
            color: #303133;
            font-weight: bold;
        }
        .el-tabs__active-bar {
            height: 3px;
            background: #303133;
        }
        .el-tabs__nav-wrap::after{
            height: 1px;
        }
        .el-tabs__header {
            margin: 0 0 30px 0;
        }
    }
}
  .card-first {
    border-bottom: 2px dotted #ccc;
    margin: 10px 0;
    .card-titles{
      display: flex;
      justify-content: space-between;
      color: blue;
      .first-title{
        display: flex;
        align-items:center;
        span:nth-child(1){
          font-size: 20px;
        }
        span:nth-child(2){
          margin-left: 15px;
          font-size: 20px;
        }
      }
    }
    .card-titles span:nth-child(2):hover{
      color: red;
      cursor: pointer;
    }
    .card-text {
      /deep/ .text-info {
        span{
          display: inline-block;
          margin-right: 20px;
        }
      }
      span{
        display: block;
        margin: 5px 0;
      }
      .text-why {
        display: flex;
        span:nth-child(1){
            width:80px;
        }
        span:nth-child(2){
          flex: 1
        }
      }
    }
  }
  .files-table{
     margin: 0;
    .table-search {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #c6cad636;
    }
    .el-table{
      /deep/ .el-table__body-wrapper{
        .el-table__body{
            font-size: 16px;
          .el-table__row {
            td {
              .share-share {
                display: flex;
                list-style: none;
                margin: 10px 0 0;
                padding: 0px;
                box-sizing: border-box;
                >li:nth-child(2){
                    margin-left: 40px;
                }
              }
              .noFlex {
                  flex-direction: column;
                  > li {
                      padding: 2px 0;
                      box-sizing: border-box;
                  }
                  >li:nth-child(2){
                    margin-left: 0;
                }
              }
              .share-read {
                justify-content: space-around;
                margin: 0px;
              }
              .icon-color{
                padding: 5px;
                box-sizing: border-box;
                color: black;
                border: 1px solid #dddddd;
                border-radius: 15px;
              }
            }
          }
        }
      }
    }
    .file-column-name{
      display: flex;
      justify-content: space-between;
      margin-right: 50px;
      a{
        color: initial;
        text-decoration: none;
      }
      .btns{
        font-size: 16px;
        i{
          display: inline-block;
          padding: 5px;
          margin: 0 2px;
          cursor: pointer;
        }
      }
    }
    /deep/ .el-table__row {
            td:first-child {
              font-size: 28px;
              vertical-align: top !important;
              .icon-gaizhang{
                 font-size: 28px;
                 color:#ffca09;
              }
            }
            td:last-child .cell {
              margin-top: -85px;
              .el-button--medium {
                  i {
                    font-size: 20px;
                  }
              }
            }
          }
    }
    .pagination{
      .el-pagination {
        text-align: left;
        margin-top: 10px;
      }
    }
}



</style>