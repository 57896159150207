<template>
    <div>
      <div class="files-table">
        <!-- <el-table size="small" :data="list.list" v-loading="loading" :show-header="false">
          <el-table-column
            type="index"
            width="50">
            <i class="iconfont icon-gaizhang"></i>
          </el-table-column>
          <el-table-column label="共享标题">
            <template slot-scope="scope">
              <div style="font-weight: bold;color:#009DD9;font-size:18px;cursor: pointer;" @click="goDetail(list.applyType,scope.row)">{{ scope.row.shareName }}</div>
                <ul class="share-share">
                  <li>申 请 人: {{scope.row.targetName}}</li>
                  <li>申请时间:{{scope.row.createDate|date}}</li>
                </ul>
                <ul class="share-share noFlex">
                  <li>联系电话: {{scope.row.targetValue}}</li>
                  <li>申请原因: {{scope.row.description}}</li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column
              label="访问地址"
              width="200">
              <template slot-scope="scope" style="display:flex;">
               <div>
                  <el-button type="success" v-show="(tabBtnShow&&scope.row.state!=1)&&(tabBtnShow&&scope.row.state!=2)"  size="medium" @click="changeType(scope.row.id,1)">通过</el-button>
                  <el-button type="danger"  v-show="(tabBtnShow&&scope.row.state!=1)&&(tabBtnShow&&scope.row.state!=2)" size="medium" @click="changeType(scope.row.id,2)">拒绝</el-button>
                  <el-button type="primary" v-show="((!tabBtnShow)&&scope.row.state!=1)&&((!tabBtnShow)&&scope.row.state!=2)" size="medium" @click="goConfer(scope.row)">授权</el-button>
                  <p v-show="(scope.row.state==1||scope.row.state==2)">
                    <span>状态: </span>
                    <span :style="scope.row.state|applyStateColor">{{scope.row.state|applyState}}</span>
                  </p>
               </div>
              </template>
            </el-table-column>
          </el-table> -->
            <el-table
              v-if='list.list && list.list.length != 0'
              :data="list.list"
              size="small"
              height="calc(100vh - 225px)"
              v-model="loading"
              @row-click="handleRowClickEvents"
              style="width: 100%">
              <el-table-column
                label="文件名称"
                width="300">
                  <template slot-scope="scope">
                      <div class="record-first">
                          <i class="iconfont icon-gaizhang"></i>
                          <span>{{scope.row.shareName}}</span>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" size="small" v-if="scope.row.state == 0">待授权</el-tag>
                    <el-tag type="success" size="small" v-else-if="scope.row.state == 1">已通过</el-tag>
                    <el-tag type="danger" size="small" v-else-if="scope.row.state == 2">已拒绝</el-tag>
                    <el-tag type="info" size="small" v-else>已取消</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="targetName"
                label="申请人"
                align="center">
              </el-table-column>
              <el-table-column
                prop="description"
                label="申请原因"
                show-overflow-tooltip
                align="center">
              </el-table-column>
              <!-- <el-table-column
                prop="targetValue"
                label="联系电话"
                align="center">
              </el-table-column> -->
              <el-table-column
                label="申请时间"
                align="center">
                  <template slot-scope="scope">
                    {{scope.row.createDate | date}}
                  </template>
              </el-table-column>
              <el-table-column
              label="操作"
              align="center"
              width="150">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if="scope.row.state == 1 || scope.row.state == 2 || scope.row.state == 3" @click.stop="goDetail(list.applyType,scope.row)">详情</el-button>
                    <el-button type="text" v-if="(tabBtnShow&&scope.row.state!=1)&&(tabBtnShow&&scope.row.state!=2)&&(tabBtnShow&&scope.row.state!=3)"  size="small" @click.stop="changeType(scope.row.id,1)">通过</el-button>
                    <el-button type="text" v-if="(tabBtnShow&&scope.row.state!=1)&&(tabBtnShow&&scope.row.state!=2)&&(tabBtnShow&&scope.row.state!=3)" size="small" @click.stop="changeType(scope.row.id,2)">拒绝</el-button>
                    <el-button type="text" v-if="((!tabBtnShow)&&scope.row.state!=1)&&((!tabBtnShow)&&scope.row.state!=2)&&((!tabBtnShow)&&scope.row.state!=3)" size="small" @click.stop="goConfer(scope.row)">授权</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="no-data-box" v-if='list.list && list.list.length == 0'>
              <img src="@/assets/no-file.svg" alt="">
              <span>暂无数据</span>
          </div>

            <ConfereditDialog ref="conferEditPages" @refreshList="refreshList"></ConfereditDialog>
            <ReadconferDialog ref="readConferDialog" @refreshList="refreshList" @refreshListRequest="refreshList"></ReadconferDialog>
        </div>
    </div>
</template>
<script>
  import { requestAPI, shareAPI, qrReadAPI } from 'liankong-ui-api' 
  import ConfereditDialog from '../liankong/components/ConferEditDialog.vue'
  import ReadconferDialog from '../liankong/components/ReadConferDialog.vue'
  export default {
    data(){
      return {
        loading:true,
        list:{} // list数据
      }
    },
    components: {
      ConfereditDialog,
      ReadconferDialog
    },
    props: {
      tabBtnShow: {
        type: Boolean,
        default: true
      }
    },
    computed: {
    },
    methods:{
      handleRowClickEvents(row, column, event){
        if(row.state == 0) { // 也可以跳到详情页
          this.goDetail(this.list.applyType, row)
        }
      },
      goDetail(applyType,data){
        if(applyType==='request'){
         this.$mapData.put('MyConferDateil',data)
        //  this.$router.replace({path:'/sharetion/mydetail'})
          this.$refs.readConferDialog.dialogVisible = true;
          this.$nextTick(()=>{
            this.$refs.readConferDialog.onLoadReadInfo()
          })
        } else {
          this.goConfer(data)
        }
      },
      goConfer(data){
         this.$mapData.put('MyConferEdit',data)
        //  this.$router.replace({path:'/confer/conferedit'})
        this.$refs.conferEditPages.dialogVisible = true;
        this.$nextTick(()=>{
          this.$refs.conferEditPages.onInitEdit()
        })
      },
      changeType(id,state) {
         const loading = this.$loading({
          lock: true,
          text: '正在审核中请等待。。。',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        requestAPI.apply(id,{
          state
        }).then(({code,data,message})=>{
            if(code == 0){
              loading.close();
              this.$message.success('审核成功')
              this.$emit('onloaData')
            } else {
              loading.close();
              this.$message.error(message)
            }
        })
      },
      refreshList(){
        this.$emit('onLoadRefreshList')
      }
    },
    created(){
    }
  }
</script>
<style lang="less" scoped>
  // .files-table{
  //    margin: 0;
  //   .table-search {
  //     width: 100%;
  //     height: 50px;
  //     display: flex;
  //     justify-content: space-between;
  //     border-bottom: 1px solid #c6cad636;
  //   }
  //   .el-table{
  //     /deep/ .el-table__body-wrapper{
  //       .el-table__body{
  //         font-size: 16px;
  //         .el-table__row {
  //           position: relative;
  //           td {
  //             .share-share {
  //               display: flex;
  //               list-style: none;
  //               margin: 10px 0 0;
  //               padding: 0px;
  //               box-sizing: border-box;
  //               >li:nth-child(2){
  //                 margin-left: 40px;
  //               }
  //             }
  //             .noFlex {
  //               flex-direction: column;
  //               > li {
  //                 padding: 2px 0;
  //                 box-sizing: border-box;
  //               }
  //               >li:nth-child(2){
  //                 margin-left: 0;
  //               }
  //             }
  //             .share-read {
  //               justify-content: space-around;
  //               margin: 0px;
  //             }
  //             .icon-color{
  //               padding: 5px;
  //               box-sizing: border-box;
  //               color: black;
  //               border: 1px solid #dddddd;
  //               border-radius: 15px;
  //             }
  //           }
  //           td:first-child {
  //             font-size: 28px;
  //             vertical-align: top !important;
  //             .icon-gaizhang{
  //                font-size: 28px;
  //                color:#ffca09;
  //             }
  //           }
  //           td:last-child {
  //           .cell {
  //             position: absolute;
  //             top: 10px;
  //             .el-button--medium {
  //                 i {
  //                   font-size: 20px;
  //                 }
  //             }
  //           }
  //          }
  //         }
  //       }
  //     }
  //   }
  //   .file-column-name{
  //     display: flex;
  //     justify-content: space-between;
  //     margin-right: 50px;

  //     a{
  //       color: initial;
  //       text-decoration: none;
  //     }

  //     .btns{
  //       font-size: 16px;
  //       i{
  //         display: inline-block;
  //         padding: 5px;
  //         margin: 0 2px;
  //         cursor: pointer;
  //       }
  //     }
    
  //   }
  //   }
  /deep/ .el-table {
    .record-first {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        i {
            color:#ffaf1d;
            font-size:23px;
            margin-right:5px;
        }
        span {
            display: block;
            width: 250px; 
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
        .doc-pic {
            width: 28px;
            height: 28px;
            margin-bottom: 5px;
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.pagination{
  text-align: right;
  background-color: #ffffff;
  padding: 15px 0;
  margin: 0 10px;
}
</style>

